export default function Logo() {
  return (
    <div className="flex items-center">
      <svg width="200" height="32" viewBox="0 0 200 32" className="h-8 w-auto">
        <text
          x="0"
          y="24"
          className="font-bold"
          style={{
            fill: '#84cc16',
            fontSize: '28px',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          comm
        </text>
        <text
          x="85"
          y="24"
          className="font-bold"
          style={{
            fill: '#84cc16',
            fontSize: '28px',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          fort
        </text>
        <g transform="translate(85, 26)">
          <rect x="0" y="0" width="6" height="3" fill="#dc2626" />
          <rect x="8" y="0" width="6" height="3" fill="#dc2626" />
          <rect x="16" y="0" width="6" height="3" fill="#dc2626" />
          <rect x="24" y="0" width="6" height="3" fill="#dc2626" />
          <rect x="32" y="0" width="6" height="3" fill="#dc2626" />
          <rect x="44" y="0" width="2" height="3" fill="#dc2626" />
        </g>
      </svg>
    </div>
  );
}