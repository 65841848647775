import React from 'react';

export default function Features() {
  const features = [
    'обмен сообщениями в общем чате и лично',
    'передача файлов любого объема',
    'аудио/видео звонки и конференции',
    'демонстрация экрана рабочего стола',
    { text: 'передача управления компьютером', disabled: true },
    { text: 'доска объявлений', disabled: true }
  ];

  return (
    <section id="features" className="relative min-h-screen pt-24">
      <div className="relative z-10 container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-start">
          <div className="relative">
            <div className="absolute -top-20 -right-20 w-72 h-72 bg-violet-600/20 rounded-full filter blur-3xl animate-pulse"></div>
            <div className="absolute -bottom-20 -left-20 w-72 h-72 bg-fuchsia-600/10 rounded-full filter blur-3xl animate-pulse" style={{ animationDelay: '1s' }}></div>
            <img
              src="https://images.unsplash.com/photo-1629654297299-c8506221ca97?auto=format&fit=crop&q=80"
              alt="Modern communication setup"
              className="rounded-2xl shadow-2xl border border-zinc-800/50 transition-transform duration-700 hover:scale-105"
            />
            <div className="absolute inset-0 bg-gradient-to-tr from-violet-600/20 to-transparent rounded-2xl"></div>
          </div>

          <div className="space-y-6">
            <h1 className="text-4xl md:text-5xl font-bold leading-tight">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-violet-400 to-fuchsia-500">
                Основные функции{' '}
              </span>
              <span className="text-white">
                чата
              </span>
            </h1>
            <div className="text-lg text-zinc-300 space-y-4">
              <ul className="space-y-3">
                {features.map((feature, index) => (
                  <li key={index} className="flex items-start">
                    <span className="text-violet-400 mr-2">•</span>
                    {typeof feature === 'string' ? (
                      <span>{feature}</span>
                    ) : (
                      <span className="opacity-50">{feature.text} (отключено)</span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}