import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";

export default function Manual() {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = 'https://commfort.com/download/commfort_client.msi';
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
    setTimeout(() => {
      window.close();
    }, 100);
  };

  const steps = [
    {
      title: "Шаг 1: Скачивание",
      content: "Скачайте установщик CommFort с официального сайта",
      image: "https://ltdfoto.ru/images/2024/11/16/2024-11-16_19-46-18.png",
      action: (
        <Button
          size="lg"
          className="mt-4 bg-gradient-to-r from-violet-600 to-fuchsia-600 hover:from-violet-700 hover:to-fuchsia-700 text-white px-8 py-4 text-lg rounded-full shadow-lg shadow-violet-600/30 transition-all hover:scale-105"
          onClick={handleDownload}
        >
          Скачать сейчас
          <Download className="ml-2 h-6 w-6" />
        </Button>
      ),
      overlay: "from-zinc-900/95 via-zinc-900/90 to-transparent"
    },
    {
      title: "Шаг 2: Запуск установки",
      content: "Запустите скачанный файл и нажмите «Next» для начала установки",
      image: "https://ltdfoto.ru/images/2024/11/16/Clipboard_11-16-2024_01.jpg",
      imageStyle: "object-contain bg-white"
    },
    {
      title: "Шаг 3: Подключение",
      content: "1. Введите адрес сервера: 185.5.249.246\n2. Придумайте никнейм и выберите пол\n3. Нажмите «ОК» для входа в чат",
      image: "https://ltdfoto.ru/images/2024/11/16/Clipboard_11-16-2024_02.jpg"
    }
  ];

  return (
    <section id="manual" className="relative min-h-screen pt-24">
      <div className="relative z-10 container mx-auto px-4 py-16">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-4xl font-bold text-center mb-12">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-violet-400 to-fuchsia-500">
              Инструкция по установке
            </span>
          </h2>

          <Carousel className="w-full max-w-3xl mx-auto">
            <CarouselContent>
              {steps.map((step, index) => (
                <CarouselItem key={index}>
                  <Card className="bg-zinc-900/50 border-zinc-800">
                    <CardContent className="flex flex-col gap-6 p-6">
                      <div className="relative aspect-video overflow-hidden rounded-lg">
                        <img
                          src={step.image}
                          alt={step.title}
                          className={`w-full h-full ${step.imageStyle || 'object-cover'}`}
                        />
                        <div className={`absolute inset-0 bg-gradient-to-t ${step.overlay || 'from-zinc-900/80 to-transparent'}`}></div>
                        <div className="absolute bottom-0 left-0 right-0 p-6">
                          <h3 className="text-2xl font-bold text-white mb-2">{step.title}</h3>
                          <p className="text-lg text-zinc-200 whitespace-pre-line">{step.content}</p>
                          {step.action}
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <span className="text-sm text-zinc-400">
                          Шаг {index + 1} из {steps.length}
                        </span>
                      </div>
                    </CardContent>
                  </Card>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious className="left-[-5rem] h-16 w-16 bg-violet-600/90 hover:bg-violet-600 border-violet-500 shadow-lg shadow-violet-500/20 transition-all hover:scale-110" />
            <CarouselNext className="right-[-5rem] h-16 w-16 bg-violet-600/90 hover:bg-violet-600 border-violet-500 shadow-lg shadow-violet-500/20 transition-all hover:scale-110" />
          </Carousel>
        </div>
      </div>
    </section>
  );
}