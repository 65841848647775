import { Download, AlertTriangle, FileText } from 'lucide-react';
import { Button } from "@/components/ui/button";

export default function Home() {
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleDownload = () => {
    window.open('https://commfort.com/download/commfort_client.msi', '_blank', 'noopener,noreferrer');
  };

  return (
    <section id="home" className="relative min-h-screen">
      <div className="absolute top-24 left-0 right-0 z-20">
        <div className="container mx-auto px-4">
          <div className="bg-violet-950/50 backdrop-blur-sm border border-violet-500/20 rounded-lg py-4 px-6 flex items-center justify-center gap-3 max-w-xl mx-auto shadow-lg shadow-violet-500/10">
            <AlertTriangle className="w-6 h-6 text-violet-400" />
            <span className="text-violet-200 font-semibold text-xl">
              Чат работает в тестовом режиме
            </span>
          </div>
        </div>
      </div>

      <div className="relative z-10 container mx-auto px-4">
        <div className="min-h-[85vh] grid lg:grid-cols-2 gap-16 items-center pt-48">
          <div className="space-y-8">
            <div className="space-y-4">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight">
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-violet-400 to-fuchsia-500">
                  Междуреченский чат{' '}
                </span>
                <span className="text-white">
                  CommFort
                </span>
              </h1>
            </div>

            <p className="text-xl text-zinc-300 max-w-xl leading-relaxed">
              Знакомься с новыми людьми, общайся с друзьями по текстовой и аудио-видео связи, обменивайся файлами, обсуждай городские события и не только!
            </p>

            <div className="flex flex-wrap gap-4">
              <Button
                size="lg"
                className="bg-violet-600 hover:bg-violet-700 text-white px-8 py-6 text-lg rounded-full shadow-lg shadow-violet-600/30 transition-all hover:scale-105"
                onClick={handleDownload}
              >
                СКАЧАТЬ
                <Download className="ml-2 h-5 w-5" />
              </Button>

              <Button
                size="lg"
                className="bg-gradient-to-r from-fuchsia-600/10 to-violet-600/10 hover:from-fuchsia-600/20 hover:to-violet-600/20 text-fuchsia-300 border border-fuchsia-500/50 px-8 py-6 text-lg rounded-full shadow-lg transition-all hover:scale-105 backdrop-blur-sm"
                onClick={() => scrollToSection('manual')}
              >
                Инструкция по установке
                <FileText className="ml-2 h-5 w-5 text-violet-400" />
              </Button>
            </div>
          </div>

          <div className="relative hidden lg:block">
            <div className="absolute -top-20 -right-20 w-72 h-72 bg-violet-600/30 rounded-full filter blur-3xl"></div>
            <div className="absolute -bottom-20 -left-20 w-72 h-72 bg-fuchsia-600/20 rounded-full filter blur-3xl"></div>
            <div className="relative">
              <img
                src="https://images.unsplash.com/photo-1603481546579-65d935ba9cdd?auto=format&fit=crop&q=80"
                alt="Modern gaming workspace"
                className="rounded-2xl shadow-2xl border border-zinc-800/50"
              />
              <div className="absolute inset-0 bg-gradient-to-tr from-violet-600/20 to-transparent rounded-2xl"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}