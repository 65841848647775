import { useState, useEffect } from 'react';
import { Monitor, MessageSquare, FileText, Download } from 'lucide-react';
import Logo from '@/components/Logo';
import Home from '@/components/Home';
import Features from '@/components/Features';
import Manual from '@/components/Manual';

export default function App() {
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      sections.forEach(section => {
        const rect = section.getBoundingClientRect();
        if (rect.top >= -300 && rect.top <= 300) {
          setActiveSection(section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="min-h-screen bg-zinc-900 text-zinc-100">
      {/* Dynamic Backgrounds */}
      <div className="fixed inset-0 z-0 transition-opacity duration-1000"
           style={{ opacity: activeSection === 'home' ? 1 : 0 }}>
        <div className="absolute inset-0">
          <img
            src="https://img.geliophoto.com/mzhdr/05_mzhdr.jpg?auto=format&fit=crop&q=80"
            alt="Modern Tech Background"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-violet-950/80 via-zinc-900/90 to-zinc-950/95"></div>
          <div className="absolute inset-0 bg-grid-white/[0.02] bg-[size:50px_50px]"></div>
        </div>
      </div>

      <div className="fixed inset-0 z-0 transition-opacity duration-1000"
           style={{ opacity: activeSection === 'features' ? 1 : 0 }}>
        <div className="absolute inset-0">
          <img
            src="https://img.geliophoto.com/mzhdr/06_mzhdr.jpg?auto=format&fit=crop&q=80"
            alt="Computer Setup Background"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-indigo-950/80 via-zinc-900/90 to-zinc-950/95"></div>
          <div className="absolute inset-0 bg-grid-white/[0.02] bg-[size:50px_50px]"></div>
        </div>
      </div>

      <div className="fixed inset-0 z-0 transition-opacity duration-1000"
           style={{ opacity: activeSection === 'manual' ? 1 : 0 }}>
        <div className="absolute inset-0">
          <img
            src="https://img.geliophoto.com/mzhdr/03_mzhdr.jpg?auto=format&fit=crop&q=80"
            alt="Manual Background"
            className="h-full w-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-fuchsia-950/80 via-zinc-900/90 to-zinc-950/95"></div>
          <div className="absolute inset-0 bg-grid-white/[0.02] bg-[size:50px_50px]"></div>
        </div>
      </div>

      {/* Navigation */}
      <nav className="fixed top-0 w-full z-50">
        <div className="absolute inset-0 bg-zinc-900/30 backdrop-blur-md"></div>
        <div className="container mx-auto px-4 relative">
          <div className="flex items-center justify-between h-20">
            <Logo />
            <div className="flex items-center space-x-1">
              {[
                { id: 'home', label: 'ГЛАВНАЯ', icon: <Monitor className="w-4 h-4" /> },
                { id: 'features', label: 'ОПИСАНИЕ', icon: <MessageSquare className="w-4 h-4" /> },
                { id: 'manual', label: 'ИНСТРУКЦИЯ', icon: <FileText className="w-4 h-4" /> },
                { id: 'download', label: 'СКАЧАТЬ', icon: <Download className="w-4 h-4" /> },
              ].map(({ id, label, icon }) => (
                <button
                  key={id}
                  onClick={() => scrollToSection(id)}
                  className={`relative group px-4 py-2 rounded-lg transition-all duration-300 ease-out ${
                    activeSection === id 
                      ? 'text-violet-300' 
                      : 'text-zinc-400 hover:text-violet-300'
                  }`}
                >
                  <div className="relative z-10 flex items-center space-x-2">
                    {icon}
                    <span className="font-medium tracking-wide text-sm">{label}</span>
                  </div>
                  
                  <div className={`absolute inset-0 rounded-lg transition-all duration-300 ${
                    activeSection === id
                      ? 'bg-violet-500/10 border border-violet-500/20'
                      : 'border border-transparent group-hover:border-violet-500/10 group-hover:bg-violet-500/5'
                  }`}>
                    <div className={`absolute inset-0 rounded-lg transition-opacity duration-300 ${
                      activeSection === id
                        ? 'opacity-100'
                        : 'opacity-0 group-hover:opacity-100'
                    }`}>
                      <div className="absolute inset-0 rounded-lg bg-violet-500/20 blur-md"></div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
        
        <div className="absolute bottom-0 left-0 right-0 h-[1px] bg-gradient-to-r from-transparent via-violet-500/20 to-transparent"></div>
      </nav>

      {/* Main Content */}
      <main className="relative z-10">
        <Home />
        <Features />
        <Manual />
      </main>
    </div>
  );
}